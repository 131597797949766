import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 'bold',
    },
    grey: {
      backgroundColor: theme.palette.secondary.light,
    },
  })
);

const PrivacyPage = () => {
  useAnalyticsSessionData();

  const classes = useStyles();

  return (
    <GenericPage>
      <SEO title="Privacy policy" />
      <Box>
        <Box
          p="6% 0% 1% 10%"
          width="100%"
          alignItems="left"
          className={classes.grey}
        >
          <Typography variant="h4">Privacy policy</Typography>
          <p>
            <em>Last updated 27 April 2020</em>
          </p>
        </Box>
        <Box p="1% 10% 3% 10%" width="100%">
          <p>
            <strong>1. IMPORTANT INFORMATION AND WHO WE ARE</strong>
          </p>
          <p>
            Build To Rent, and/or its affiliates and related companies (“we”, or
            “us”) operate the buildtorent.io website and other related websites
            (collectively our “Site”). This Privacy Policy sets out our firm
            commitment to the privacy of our users (“users” or “you”) and how we
            collect and use your personal data. Please read this Privacy Policy
            carefully. By accessing our Site, using our services and, where
            relevant, expressly accepting this policy, you acknowledge that you
            have read and agree to the terms of this Privacy Policy. If you do
            not agree to this Privacy Policy please do not use our Site. We
            respect your right to privacy and will only process personal
            information you provide to us in accordance with the applicable
            privacy legislation in the jurisdictions in which we operate,
            including the General Data Protection Regulation ((EU) 2016/679) and
            any national implementing laws, regulations and secondary
            legislation, as amended or updated from time to time and then any
            successor legislation to the GDPR.
          </p>
          <p>
            Please read this Privacy Policy carefully. By accessing our Site,
            using our services and, where relevant, expressly accepting this
            policy, you acknowledge that you have read and agree to the terms of
            this Privacy Policy. If you do not agree to this Privacy Policy
            please do not use our Site.
          </p>
          <p>
            We respect your right to privacy and will only process personal
            information you provide to us in accordance with the applicable
            privacy legislation in the jurisdictions in which we operate,
            including the General Data Protection Regulation ((EU) 2016/679) and
            any national implementing laws, regulations and secondary
            legislation, as amended or updated from time to time and then any
            successor legislation to the GDPR.
          </p>
          <p>
            <strong>Controller</strong>
            <br />
            Our Site is operated by B2Rent Ltd, acting as a data controller,
            (“We” or “us”). Our office is located at 63/66 Hatton Garden, Fifth
            Floor, Suite 23, London EC1N 8LE.
          </p>
          <p>
            <strong>Contact details</strong>
            <br />
            If you have any questions about how we collect, store and use
            personal information, or if you have any other privacy-related
            questions, please contact us by any of the following means:
            <ul>
              <li>e-mail us at: info@buildtorent.io;</li>
              <li>call us at: +44 203 633 9779;</li>
              <li>or write to us at our office address above.</li>
            </ul>
          </p>
          <p>
            <strong>
              Changes to the privacy policy and your duty to inform us of
              changes
            </strong>
            <br />
            We may from time to time, make changes to this Privacy Policy. We
            will set out any important changes at the end of the Privacy Policy
            and you should check back regularly to check to see if there have
            been any changes to this Privacy Policy.
          </p>
          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            your relationship with us.
          </p>
          <p>
            <strong>Third-party links</strong>
            <br />
            Our Site may contain links to third party sites, including sites
            with which we have affiliate agreements. We are not responsible for
            the privacy practices or the content of such third party websites.
            This Privacy Policy extends only to personal data we collect from
            you either via our Site, or in our dealings with you. We recommend
            that you check the privacy and security policies and procedures of
            each and every other website that you visit.
          </p>
          <p>
            <strong>2. THE DATA WE COLLECT ABOUT YOU</strong>
          </p>
          <p>
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
          </p>
          <p>
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
          </p>
          <ul>
            <li>
              <strong>Identity Data</strong> includes first name, last name,
              username or similar identifier, date of birth, gender, passport or
              national ID card data.
            </li>
            <li>
              <strong>Contact Data</strong> includes postal address, email
              address, telephone numbers and instant messaging account details.
            </li>
            <li>
              <strong>Financial Data </strong>includes bank account and payment
              card details.
            </li>
            <li>
              <strong>Transaction Data</strong> includes details about payments
              processed via our Site.
            </li>
            <li>
              <strong>Technical Data</strong> includes internet protocol (IP)
              address, your login data, browser type and version, time zone
              setting and location, locale, UTM parameters, attribution
              parameters, browser plug-in types and versions, and operating
              system, platform and other technology on the devices you use to
              access our Site.
            </li>
            <li>
              <strong>Profile Data</strong> includes your username and password,
              bookings made by you, your interests, the city or property you are
              interested in, your study course and destination, preferences
              (e.g. roommate, dates, budget), guarantor information, feedback
              and survey responses.
            </li>
            <li>
              <strong>Usage Data</strong> includes information about how you use
              our Site.
            </li>
            <li>
              <strong>Marketing and Communications Data</strong> includes your
              preferences in receiving marketing from us and third parties and
              your communication preferences.
            </li>
          </ul>
          <p>
            We also collect, use and share <strong>Aggregated Data</strong> such
            as statistical or demographic data for any purpose. Aggregated Data
            may be derived from your personal data but is not considered
            personal data as this data does not directly or indirectly reveal
            your identity. For example, we may aggregate your Usage Data to
            calculate the percentage of users accessing a specific website
            feature. However, if we combine or connect Aggregated Data with your
            personal data so that it can directly or indirectly identify you, we
            treat the combined data as personal data which will be used in
            accordance with this Privacy Policy.
          </p>
          <p>
            We do not collect any{' '}
            <strong>Special Categories of Personal Data</strong> about you (this
            includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and
            genetic and biometric data), unless specifically provided by you as
            part of your use of our service. We do not collect any information
            about criminal convictions and offences.
          </p>
          <p>
            <strong>3. HOW IS YOUR PERSONAL DATA COLLECTED?</strong>
          </p>
          <p>
            We may collect information about you in a number of ways, including:
          </p>
          <ul>
            <li>
              <strong>Direct interactions</strong>. Most of the personal data we
              collect about you will be personal data you provide to us. For
              example, you may give us your Identity, Contact and Financial Data
              by filling in a form on our Site or if you correspond with us via
              phone, email, instant messaging service, speak to us at an event
              or otherwise. This includes personal data you provide when you:
              <ul>
                <li>enquire about our products or services;</li>
                <li>create an account on our Site;</li>
                <li>make a booking via our Site;</li>
                <li>request marketing to be sent to you;</li>
                <li>enter a competition, promotion or survey; or</li>
                <li>give us feedback.</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <strong>Automated technologies or interactions</strong>. As you
              interact with our Site, we may automatically collect Technical
              Data such as information about your browser, what areas of our
              Site you visit and what links you click on, browsing actions and
              patterns. This helps us to provide you with a good experience when
              you browse our Site and also allows us to improve our Site. We
              collect this personal data by using cookies and other similar
              technologies. We may also receive Technical Data about you if you
              visit other websites employing our cookies and other similar
              technologies. For detailed information on the cookies we use and
              the purposes for which we use them see our{' '}
              <a href="/cookies">Cookie Policy</a>.
            </li>
          </ul>
          <ul>
            <li>
              <strong>Third parties or publicly available sources</strong>. We
              may receive personal data about you from various third parties and
              public sources as set out below:
              <ul>
                <li>
                  Technical Data from analytics and search information
                  providers;
                </li>
                <li>
                  Identity and Contact Data from affiliates, referral partners
                  or advertisers on our Site;
                </li>
                <li>
                  If you log in using social media depending on how you log in
                  we may collect Contact, Identity and Technical Data,
                  including: your profile name/username, profile picture, email
                  address and date of birth. The social media information you
                  consent to share with us will be combined with the other
                  information you provide to us or that we collect about you. We
                  will also collect information about your comments, posts and
                  &#8216;likes&#8217; to allow us to administer our Site and
                  improve the user experience.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            We will also keep a record of our conversations with you, whether by
            phone, messages or in person.
          </p>
          <p>
            <strong>4. HOW WE USE YOUR PERSONAL DATA</strong>
          </p>
          <p>
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <ul>
            <li>
              Where we need to perform the contract we are about to enter into
              or have entered into with you.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li>
              Where we need to comply with a legal or regulatory obligation.
            </li>
          </ul>
          <p>
            <strong>Purposes for which we will use your personal data</strong>
          </p>
          <p>
            We have set out below a description of the ways we will use your
            personal data, and which of the legal bases we rely on to do so. We
            have also identified what our legitimate interests are where
            appropriate.
          </p>
          <p>
            Note that we may process your personal data for more than one lawful
            ground depending on the specific purpose for which we are using your
            data.
          </p>
          <table cellSpacing="1" cellPadding="1">
            <thead>
              <tr>
                <th scope="col">Purpose/Activity</th>
                <th scope="col">
                  Lawful basis for processing including basis of legitimate
                  interest
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>To register you as a new user</td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>To process and complete your booking</td>
                <td>Performance of a contract with you</td>
              </tr>
              <tr>
                <td>
                  To manage our relationship with you, which will include:
                  <br />
                  (a) Notifying you about changes to our terms or Privacy Policy
                  <br />
                  (b) Asking you to leave a review or take a survey
                </td>
                <td>
                  (a) Performance of a contract with you
                  <br />
                  (b) Necessary to comply with a legal obligation
                  <br />
                  (c) Necessary for our legitimate interests (to keep our
                  records updated and to study how customers use our services)
                </td>
              </tr>
              <tr>
                <td>
                  To enable you to partake in a prize draw, competition or
                  complete a survey
                </td>
                <td>
                  (a) Performance of a contract with you
                  <br />
                  (b) Necessary for our legitimate interests (to study how
                  customers use our services, to develop them and grow our
                  business)
                </td>
              </tr>
              <tr>
                <td>
                  To administer and protect our business and the Site (including
                  troubleshooting, data analysis, testing, system maintenance,
                  support, reporting and hosting of data)
                </td>
                <td>
                  (a) Necessary for our legitimate interests (for running our
                  business, provision of administration and IT services, network
                  security, to prevent fraud and in the context of a business
                  reorganisation or group restructuring exercise)
                  <br />
                  (b) Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>
                  To deliver relevant Site content and advertisements to you and
                  measure or understand the effectiveness of the advertising we
                  serve to you
                </td>
                <td>
                  Necessary for our legitimate interests (to study how customers
                  use our services, to develop them, to grow our business and to
                  inform our marketing strategy)
                </td>
              </tr>
              <tr>
                <td>
                  To use data analytics to improve our Site, services,
                  marketing, customer relationships and experiences
                </td>
                <td>
                  Necessary for our legitimate interests (to define types of
                  customers for our services, to keep our Site updated and
                  relevant, to develop our business and to inform our marketing
                  strategy)
                </td>
              </tr>
              <tr>
                <td>
                  To make suggestions and recommendations to you about goods or
                  services that may be of interest to you
                </td>
                <td>
                  Necessary for our legitimate interests (to develop our
                  services and grow our business)
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Payments</strong>
            <br />
            Where you make a payment via our Site we will use one of our payment
            providers, such as Stripe, to process your payment. In connection
            with the processing of such payments, we do not retain your payment
            information (including Financial Data). Rather, all such information
            related to your transaction is provided directly to the payment
            provider, whose use of your personal data is governed by their
            privacy policy. You can find out more about how Stripe collects and
            uses your personal data by reviewing their privacy policy{' '}
            <a href="https://stripe.com/gb/privacy">here</a> before completing a
            transaction on our Site. By using our services and making payments
            via our Site, you are agreeing to Stripe use of your personal data
            in accordance with their privacy policies.
          </p>
          <p>
            <strong>Marketing</strong>
            <br />
            We may use your Identity, Contact, Technical, Usage and Profile Data
            to form a view on what we think you may want or need, or what may be
            of interest to you. This is how we decide which products, services
            and offers may be relevant for you for marketing purposes.
          </p>
          <p>
            You will receive marketing communications from us if you have
            requested information from us or purchased services from us or if
            you provided us with details when you entered a competition or
            registered for a promotion and, in each case, you have not opted-out
            of receiving that marketing.
            <br />
            We will obtain your express opt-in consent before we share your
            personal data with any company outside the Build To Rent group of
            companies for marketing purposes.
          </p>
          <p>
            If at any time after providing us with your personal data you no
            longer want to receive such marketing from us or third parties you
            can contact us at{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
          </p>
          <p>
            When you opt-out of receiving these marketing messages, this will
            not apply to personal data provided to us by you as part of a
            transaction with us.
          </p>
          <p>
            <strong>Change of purpose</strong>
            <br />
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to receive an explanation as to how the
            processing for the new purpose is compatible with the original
            purpose please contact us at{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
            <br />
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
            <br />
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </p>
          <p>
            <strong>5. DISCLOSURES OF YOUR PERSONAL DATA</strong>
          </p>
          <p>
            In some instances, we may need to share your personal data with the
            parties set out below:
          </p>
          <ul>
            <li>Internal Third Parties as set out in the Glossary.</li>
            <li>External Third Parties as set out in the Glossary.</li>
            <li>
              Specific third parties such as property advertisers and affiliate
              third parties so that they can get in touch about your booking or
              help answer your query. Equally, an advertiser may share your
              personal data with us in order to facilitate a booking made via
              our Sites.
            </li>
            <li>
              Third parties to whom we may choose to sell, transfer, or merge
              parts of our business or our assets. Alternatively, we may seek to
              acquire other businesses or merge with them. If a change happens
              to our business, then the new owners may use your personal data in
              the same way as set out in this Privacy Policy.
            </li>
          </ul>
          <p>
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third-party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
          </p>
          <p>
            <strong>6. INTERNATIONAL TRANSFERS</strong>
          </p>
          <p>
            In order to provide you with our products and services, we may need
            to store or transfer your personal data to countries inside and
            outside the European Economic Area, some of which do not protect
            privacy rights as extensively as in the European Economic Area. In
            some instances your personal data will be transferred to our group
            companies based outside of the EEA to allow them to contact you
            about our products and services. By submitting your personal data,
            you agree to this transfer, storing and/or processing.
          </p>
          <p>
            You agree that we may also transfer your personal data, including
            Financial Data, to individual advertisers or banks located outside
            the EEA (and reciprocally, the bank details of advertisers to
            students), in order to facilitate the execution of the tenancy
            agreement between students and advertisers. Build To Rent does not
            store your Financial Data after it has been transferred.
          </p>
          <p>
            <strong>7. DATA SECURITY</strong>
          </p>
          <p>
            We take appropriate security measures (including physical,
            electronic and procedural measures) to help safeguard your personal
            data from unauthorized access and disclosure. When transmitting your
            personal information to a third party (such as an advertiser) in
            order to complete a request made by you on our Site, we will use
            commercially reasonable means to secure your personal data.
            Notwithstanding the foregoing, we assume no liability for
            interception, alteration or misuse of information transmitted over
            the internet, except for losses incurred as a result of our gross
            negligence or default.
          </p>
          <p>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </p>
          <p>
            <strong>8. DATA RETENTION</strong>
          </p>
          <p>
            We will only retain your personal data for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature, and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal requirements.
          </p>
          <p>
            In some circumstances we may anonymise your personal data (so that
            it can no longer be associated with you) for research or statistical
            or accounting purposes in which case we may use this information
            indefinitely without further notice to you.
          </p>
          <p>
            <strong>9. YOUR LEGAL RIGHTS</strong>
            <br />
            In certain circumstances, you have rights under data protection laws
            in relation to your personal data, including:
          </p>
          <ul>
            <li>the right to request access to your personal data;</li>
            <li>the right to request correction of your personal data;</li>
            <li>the right to request erasure of your personal data;</li>
            <li>
              the right to object to the processing of your personal data;
            </li>
            <li>
              the right to request restriction of processing your personal data;
            </li>
            <li>the right to request transfer of your personal data; and</li>
            <li>the right to withdraw consent.</li>
          </ul>
          <p>
            If you wish to exercise any of the above rights, please write to us
            (either by post or by e-mail) at the address specified above.
          </p>
          <p>
            <strong>No fee usually required</strong>
            <br />
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we may refuse to comply with your request
            in these circumstances.
          </p>
          <p>
            <strong>What we may need from you</strong>
            <br />
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that your personal data is not disclosed to any
            person who has no right to receive it. We may also contact you to
            ask you for further information in relation to your request to speed
            up our response.
          </p>
          <p>
            <strong>Time limit to respond</strong>
            <br />
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
          <p>
            <strong>10. GLOSSARY</strong>
          </p>
          <p>
            <strong>Lawful basis</strong>
          </p>
          <p>
            <strong>Legitimate Interest</strong> means our interest in
            conducting and managing our business to enable us to give you the
            best service/product and the best and most secure experience. We
            make sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your
            personal data for our legitimate interests. We do not use your
            personal data for activities where our interests are overridden by
            the impact on you (unless we have your consent or are otherwise
            required or permitted to by law). You can obtain further information
            about how we assess our legitimate interests against any potential
            impact on you in respect of specific activities by contacting us at{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
          </p>
          <p>
            <strong>Performance of Contract</strong> means processing your data
            where it is necessary for the performance of a contract to which you
            are a party or to take steps at your request before entering into
            such a contract.
          </p>
          <p>
            <strong>Comply with a legal or regulatory obligation</strong> means
            processing your personal data where it is necessary for compliance
            with a legal or regulatory obligation that we are subject to.
          </p>
          <p>
            <strong>Third parties</strong>
          </p>
          <p>
            <strong>Internal Third Parties</strong>
            <br />
            Other companies in the Build To Rent group acting as joint
            controllers or processors and who are based overseas and which
            provide services to Build To Rent.
          </p>
          <p>
            <strong>External Third Parties</strong>
          </p>
          <ul>
            <li>
              Service providers acting as processors or joint controllers based
              worldwide who provide IT, infrastructure, data processing, and
              system administration services.
            </li>
            <li>
              Professional advisers acting as processors or joint controllers
              including lawyers, bankers, auditors and insurers based worldwide
              who provide consultancy, banking, legal, insurance and accounting
              services.
            </li>
            <li>
              Revenue and taxation regulators and other government authorities
              acting as processors or joint controllers based in the countries
              in which we operate who require reporting of processing activities
              in certain circumstances.
            </li>
          </ul>
          <p>
            <strong>Your legal rights</strong>
          </p>
          <p>You have the right to:</p>
          <p>
            <strong>Request access</strong> to your personal data (commonly
            known as a &#8220;data subject access request&#8221;). This enables
            you to receive a copy of the personal data we hold about you and to
            check that we are lawfully processing it.
          </p>
          <p>
            <strong>Request correction</strong> of the personal data that we
            hold about you. This enables you to have any incomplete or
            inaccurate data we hold about you corrected, though we may need to
            verify the accuracy of the new data you provide to us.
          </p>
          <p>
            <strong>Request erasure</strong> of your personal data. This enables
            you to ask us to delete or remove personal data where there is no
            good reason for us continuing to process it. You also have the right
            to ask us to delete or remove your personal data where you have
            successfully exercised your right to object to processing (see
            below), where we may have processed your information unlawfully or
            where we are required to erase your personal data to comply with
            local law. Note, however, that we may not always be able to comply
            with your request of erasure for specific legal reasons which will
            be notified to you, if applicable, at the time of your request.
          </p>
          <p>
            <strong>Object to processing</strong> of your personal data where we
            are relying on a legitimate interest (or those of a third party) and
            there is something about your particular situation which makes you
            want to object to processing on this ground as you feel it impacts
            on your fundamental rights and freedoms. You also have the right to
            object where we are processing your personal data for direct
            marketing purposes. In some cases, we may demonstrate that we have
            compelling legitimate grounds to process your information which
            override your rights and freedoms.
          </p>
          <p>
            <strong>Request restriction of processing</strong> of your personal
            data. This enables you to ask us to suspend the processing of your
            personal data in the following scenarios: (a) if you want us to
            establish the data&#8217;s accuracy; (b) where our use of the data
            is unlawful but you do not want us to erase it; (c) where you need
            us to hold the data even if we no longer require it as you need it
            to establish, exercise or defend legal claims; or (d) you have
            objected to our use of your data but we need to verify whether we
            have overriding legitimate grounds to use it.
          </p>
          <p>
            <strong>Request the transfer</strong> of your personal data to you
            or to a third party. We will provide to you, or a third party you
            have chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.
          </p>
          <p>
            <strong>Withdraw consent at any time</strong> where we are relying
            on consent to process your personal data. However, this will not
            affect the lawfulness of any processing carried out before you
            withdraw your consent. If you withdraw your consent, we may not be
            able to provide certain products or services to you. We will advise
            you if this is the case at the time you withdraw your consent.
          </p>
          <p>
            <strong>11. COMPLAINTS</strong>
          </p>
          <p>
            If you have any concerns about our use of your personal information,
            you can make a complaint to us at{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>
            or write to us at the address above.
          </p>
          <p>
            You can also complain to the ICO if you are unhappy with how we have
            used your data.
          </p>
          <p>The ICO’s address:</p>
          <p>
            Information Commissioner’s Office
            <br />
            Wycliffe House
            <br />
            Water Lane
            <br />
            Wilmslow
            <br /> Cheshire
            <br /> SK9 5AF
            <br />
            Helpline number: 0303 123 1113 ICO
            <br />
            website: https://www.ico.org.uk
          </p>
        </Box>
      </Box>
    </GenericPage>
  );
};

export default PrivacyPage;
